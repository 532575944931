import Title from '../title/title';
import logoImg from '../../assets/img/army/logo/logo_white.png';


import React from 'react';

function Doc() {


  return (

    <section className='doc  ' id='doc'>
      <div className='doc__wrapper white'>
        <Title title='Документы' img={logoImg} desc='' />

        <ul className='doc__content' id='#doc'>
          <li className='doc__item '   >
            <p>Выписка из ЕГРЮЛ</p>
            <a href='https://storage.yandexcloud.net/vpervieruki/doc/%D0%BD%D0%BE%D0%B2%D0%BE%D0%B5/%D0%92%D1%8B%D0%BF%D0%B8%D1%81%D0%BA%D0%B0%20%D0%B8%D0%B7%20%D0%95%D0%93%D0%A0%D0%AE%D0%9B.pdf' target='_blank' className='doc__item_hide'>Посмотреть</a>
          </li>
          <li className='doc__item'  >
            <p>Устав</p>
            <a href='https://storage.yandexcloud.net/vpervieruki/doc/%D0%BD%D0%BE%D0%B2%D0%BE%D0%B5/%D0%A3%D1%81%D1%82%D0%B0%D0%B2.pdf' target='_blank' className='doc__item_hide'>Посмотреть</a>
          </li>
          <li className='doc__item' >
            <p>Свидет. о гос. регистрации</p>
            <a href='https://storage.yandexcloud.net/vpervieruki/doc/%D0%BD%D0%BE%D0%B2%D0%BE%D0%B5/%D1%81%D0%B2%D0%B8%D0%B4%D0%B5%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE%20%D0%BE%20%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D0%B8%20%D0%9D%D0%9A%D0%9E.pdf' target='_blank' className='doc__item_hide'>Посмотреть</a>
          </li>
          <li className='doc__item' >
            <p>Реквизиты фонда</p>
            <a href='https://storage.yandexcloud.net/vpervieruki/doc/%D0%BD%D0%BE%D0%B2%D0%BE%D0%B5/%D0%A0%D0%B5%D0%BA%D0%B2%D0%B8%D0%B7%D0%B8%D1%82%D1%8B%20%D0%91%D0%A4%20%D0%92%20%D0%BF%D0%B5%D1%80%D0%B2%D1%8B%D0%B5%20%D1%80%D1%83%D0%BA%D0%B8.pdf' target='_blank' className='doc__item_hide'>Посмотреть</a>
          </li>
          <li className='doc__item' >
            <p>Публичная оферта</p>
            <a href='https://storage.yandexcloud.net/vpervieruki/doc/%D0%BD%D0%BE%D0%B2%D0%BE%D0%B5/DOC072%20(2).pdf' target='_blank' className='doc__item_hide'>Посмотреть</a>
          </li>
          <li className='doc__item' >
            <p>Политика КПД</p>
            <a href='https://storage.yandexcloud.net/vpervieruki/doc/%D0%BD%D0%BE%D0%B2%D0%BE%D0%B5/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BF%D0%B4%20(2).pdf' target='_blank' className='doc__item_hide'>Посмотреть</a>
          </li>
        </ul>
      </div>

    </section>

  );
}

export default Doc;
