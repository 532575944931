import React from 'react';
import logoImg from '../assets/img/army/logo/logo.png';
import Title from './title/title';
function ContactUs() {
  return (
    <section className='contact' >
      <div className='contact__wrapper ' id='contact'>
        <Title title='Как нас найти' img={logoImg} desc='Адрес: ул. Малая Юшуньская дом 1, корпус 1.' />
        <p className='subtext'>График работы: eжедневно с 9 до 18, кроме воскресенья и понедельника.</p>
        <a className='subtext last' href='tel:+7 (916) 976-20-07'>Телефон для связи: <br/> <span>+7 (916) 976-20-07</span></a>

        <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A17714ef7fd2fc8a216cf807093d9aa4ba59e41d6bc0346f3e9e3dfc98a84bf90&amp;source=constructor" width="100%" height="400" ></iframe>
      </div>
    </section>
  );
}
export default ContactUs;

